import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Column, MenuItem, GridMenuItem } from '@bilateral-roaming/core/models';
import { FileExporterService } from '@bilateral-roaming/core/services';
import _ from 'lodash';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent {
  @ViewChild('menu') menu: any;
  @ViewChild('menuButton') menuButton: any;
  @ViewChild('gridTable') gridTable: any;

  @Input() columnDefs!: Column[];
  @Input() rowData!: any[];
  @Input() lazyLoad: boolean = false;
  @Input() loading: boolean = false;
  @Input() showEmptyMessage: boolean = false;
  @Input() scrollable: boolean = false;
  @Input() paginator: boolean = false;
  @Input() scrollHeight!: string;
  @Input() totalRecords!: number;
  @Input() showMenu = false;
  @Input() menuItems!: GridMenuItem[];
  @Input() showStatusColumn: boolean = true;
  @Input() enableSelection = true;
  @Input() virtualScroll: boolean = false;
  @Input() virtualScrollItemSize: number = 50;
  @Input() pageSize: number = 10;

  @Output() lazyLoadChanged = new EventEmitter();
  @Output() rowSelected = new EventEmitter<any>();
  @Output() rowButtonClicked = new EventEmitter();
  @Output() menuItemClicked = new EventEmitter<{
    menuItem: GridMenuItem;
    data: any;
  }>();

  MenuItem = MenuItem;
  selectedRow: any;
  JSON = JSON;

  filteredValue: any;

  statuses = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];

  constructor(private fileExportService: FileExporterService) {}

  ngOnInit(): void {}

  onRowSelect(event: any) {
    this.rowSelected.emit({ data: event.data });
  }

  clearTable() {
    this.gridTable.clear();
  }

  exportToCSV() {
    // Get Data
    let data = this.getData();
    // Downloading as csv
    this.fileExportService.toCSV('data.csv', data);
  }

  exportToExcel() {
    // Get Data
    let data = this.getData();
    // Downloading as xlsx
    this.fileExportService.toExcel('data', data);
  }

  exportToPDF() {
    console.log(this.rowData);
  }

  updateFilteredValue(event: any) {
    this.filteredValue = event.filteredValue;
  }

  getData(): any[] {
    // Using either the filteredData or the rowData
    let data = this.filteredValue || this.rowData;

    // Get the data of all the columns
    let tmp: any[] = [];
    data.forEach((e: any) => {
      let a: any = {};
      this.columnDefs.map((c: any) => {
        a[c.header] = _.get(e, c.columnDef);
      });
      tmp.push(a);
    });
    return tmp;
  }
}
