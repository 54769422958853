import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@bilateral-roaming/shared/components';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(private dialog: MatDialog) {}

  confirm(
    title: string,
    height = '120px',
    config?: MatDialogConfig
  ): Observable<boolean> {
    const dialogConfig = config || new MatDialogConfig();

    dialogConfig.height = dialogConfig.height || height;
    dialogConfig.data = dialogConfig.data || {
      title,
    };

    return this.dialog.open(ConfirmDialogComponent, dialogConfig).afterClosed();
  }
}
