import { Injectable } from '@angular/core';
import { generalConstants } from '@bilateral-roaming/config';

import { of } from 'rxjs';

import { MessagesService } from './messages.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private messagesService: MessagesService,
    private userService: UserService
  ) {}

  handleError(err: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(err.message)) {
      window.location.reload();
    }

    if (err.error?.error === 'invalid_token') {
      this.userService.logout();
    }

    if (err.error?.error === 'invalid_grant') {
      this.messagesService.showErrorMessage(
        'Invalid username or password, try again!'
      );

      return of(null);
    }

    if (err.error?.message === 'Access is denied') {
      return of(null);
    }

    const message = err.error?.message;

    this.messagesService.showErrorMessage(
      message ? message : generalConstants.generalErrorMessage
    );

    throw err;
  }
}
