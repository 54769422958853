import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { ContractingParty } from '../models/contracting-party';

@Injectable({
  providedIn: 'root',
})
export class ContractingPartiesService extends RestDataSource<ContractingParty> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(
      httpClient,
      BackendApi.contractingPartiesApi,
      errorHandlerService,
      false
    );
  }

  addUpdate(
    contractingParty: ContractingParty
  ): Observable<ContractingParty | null> {
    if (contractingParty?.id) {
      const contractingPartyToBeEdited = { ...contractingParty };

      contractingPartyToBeEdited.name = contractingParty.name.trim();
      contractingPartyToBeEdited.version = contractingParty.version;

      return this.update(contractingParty.id, contractingPartyToBeEdited);
    } else {
      const contractingPartyToBeAdded = {
        name: contractingParty.name.trim(),
        version: contractingParty.version,
      };

      return this.add(contractingPartyToBeAdded);
    }
  }
}
