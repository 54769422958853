import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  constructor(private toastrService: ToastrService) {}

  showErrorMessage(message: string) {
    return this.toastrService.error(message);
  }

  showSuccessMessage(message: string) {
    return this.toastrService.success(message);
  }
}
