import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Operator } from '../models/operators';
import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';

@Injectable({
  providedIn: 'root',
})
export class OperatorsService extends RestDataSource<Operator> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.operatorsApi, errorHandlerService, false);
  }

  addUpdate(operator: Operator): Observable<Operator | null> {
    if (operator?.id) {
      const operatorToBeEdited = { ...operator };

      operatorToBeEdited.name = operator.name.trim();
      operatorToBeEdited.version = operator.version;

      return this.update(operator.id, operatorToBeEdited);
    } else {
      const operatorToBeAdded = {
        name: operator.name.trim(),
        version: operator.version,
      };

      return this.add(operatorToBeAdded);
    }
  }
}
