import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { CommercialStatus } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CommercialStatusService extends RestDataSource<CommercialStatus> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(
      httpClient,
      BackendApi.commercialStatusApi,
      errorHandlerService,
      false
    );
  }

  addUpdate(
    commercialStatus: CommercialStatus
  ): Observable<CommercialStatus | null> {
    if (commercialStatus?.id) {
      const commercialStatusToBeEdited = { ...commercialStatus };

      commercialStatusToBeEdited.status = commercialStatus.status.trim();
      commercialStatusToBeEdited.version = commercialStatus.version;

      return this.update(commercialStatus.id, commercialStatusToBeEdited);
    } else {
      const commercialStatusToBeAdded = {
        ...commercialStatus,
        status: commercialStatus.status.trim(),
        version: commercialStatus.version,
      };

      return this.add(commercialStatusToBeAdded);
    }
  }
}
