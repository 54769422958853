<p-table
  #gridTable
  dataKey="id"
  selectionMode="{{ enableSelection ? 'single' : '' }}"
  [lazy]="lazyLoad"
  (onLazyLoad)="lazyLoadChanged.emit($event)"
  [paginator]="paginator"
  [virtualScroll]="virtualScroll"
  [virtualScrollItemSize]="virtualScrollItemSize"
  [rows]="pageSize"
  [totalRecords]="totalRecords"
  [showCurrentPageReport]="true"
  [columns]="columnDefs"
  [value]="rowData"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  styleClass="p-datatable-striped"
  (onRowSelect)="onRowSelect($event)"
  [loading]="loading"
  [scrollable]="scrollable"
  [scrollHeight]="scrollHeight"
  (onFilter)="updateFilteredValue($event)"
>
  <ng-template pTemplate="header" let-columns>
    <tr class="text-sm">
      <th
        class="font-extrabold text-center"
        *ngFor="let col of columns"
        pFrozenColumn
        [frozen]="col.sticky"
        pSortableColumn="{{ col.columnDef }}"
      >
        <span>{{ col.header }}</span>
        <p-sortIcon
          *ngIf="col.sortingConfig?.enabled || !col.sortingConfig"
          field="{{ col.columnDef }}"
        ></p-sortIcon>
        <p-columnFilter
          *ngIf="col.filteringConfig?.enabled || !col.filteringConfig"
          type="{{ col.filteringConfig?.type || 'text' }}"
          field="{{ col.columnDef }}"
          display="menu"
        >
        </p-columnFilter>
      </th>
      <th
        *ngIf="showStatusColumn"
        class="font-extrabold"
        pSortableColumn="active"
      >
        Status
        <p-sortIcon field="status"></p-sortIcon>
        <!-- <p-columnFilter
          display="menu"
          type="{{ 'boolean' }}"
          field="{{ 'active' }}"
        ></p-columnFilter> -->
      </th>
      <th *ngIf="showMenu"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item let-columns="columns">
    <tr class="text-sm pl-2" [pSelectableRow]="item">
      <td
        *ngFor="let col of columns"
        pFrozenColumn
        [frozen]="col.sticky"
        [ngStyle]="col.highlight ? { backgroundColor: '#0000DD11' } : {}"
      >
        <span *ngIf="!col.isButton && !col.isIcon">
          <span
            [innerHtml]="col.cell(item)"
            [style]="col.changeColor ? col.changeColor(item) : ''"
          ></span>
        </span>
        <span *ngIf="!col.isButton && col.isIcon">
          <ng-container *ngIf="JSON.parse(col.cell(item)) as iconDetails">
            <div
              title="{{ iconDetails.title }}"
              class="{{ iconDetails.cssClass }}"
            >
              <mat-icon> {{ iconDetails.icon }}</mat-icon>
            </div>
          </ng-container>
        </span>
        <span *ngIf="col.isButton && !col.isIcon">
          <div>
            <button
              class="h-8 bg-white hover:bg-slate-900 hover:text-white text-slate-900 font-rakuten-sans py-1 px-4 border border-slate-900 rounded"
              (click)="rowButtonClicked.emit({item, col})"
            >
              {{ col.cell(item) }}
            </button>
          </div>
        </span>
      </td>
      <td *ngIf="showStatusColumn">
        <span
          class="text-white py-1 px-2 rounded-md text-xs"
          [ngClass]="{
            'bg-green-600': item.active,
            'bg-red-600 ': !item.active
          }"
        >
          <span [ngClass]="{ 'px-1': item.active }">
            {{ item.active ? "Active" : "Inactive" }}</span
          >
        </span>
      </td>
      <td
        *ngIf="showMenu"
        class="flex justify-end"
        pFrozenColumn
        [frozen]="scrollable"
        alignFrozen="right"
      >
        <ng-container *ngFor="let menuItem of menuItems">
          <ng-container
            *ngIf="
              menuItem.label !== MenuItem.Delete &&
              menuItem.label !== MenuItem.History
            "
          >
            <button
              class="font-rakuten-sans text-sm-13 flex items-center text-center mr-3"
              *ngxPermissionsOnly="menuItem.permissions"
              (click)="menuItemClicked.emit({ menuItem, data: item})"
              title="{{ menuItem.label }}"
            >
              <mat-icon>{{ menuItem.icon }}</mat-icon>
              <!-- <span>{{ menuItem.label }}</span> -->
            </button>
          </ng-container>

          <ng-container
            *ngIf="menuItem.label == MenuItem.History && item.version > 0"
          >
            <button
              class="font-rakuten-sans text-sm-13 flex items-center text-center mr-3"
              *ngxPermissionsOnly="menuItem.permissions"
              (click)="menuItemClicked.emit({ menuItem, data: item})"
              title="{{ menuItem.label }}"
            >
              <mat-icon>{{ menuItem.icon }}</mat-icon>
              <!-- <span>{{ menuItem.label }}</span> -->
            </button>
          </ng-container>

          <ng-container *ngxPermissionsOnly="menuItem.permissions">
            <mat-slide-toggle
              *ngIf="menuItem.label === MenuItem.Delete"
              [color]="'primary'"
              name="active"
              [checked]="item.active"
              (change)="menuItemClicked.emit({ data: item, menuItem })"
              (click)="$event.stopPropagation()"
            ></mat-slide-toggle>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template *ngIf="showEmptyMessage" pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <div class="m-auto mt-[130px] min-h-[400px]">No Records Found</div>
      </td>
    </tr>
  </ng-template>
</p-table>
