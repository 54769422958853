import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { OutboundSIMExchangeStatus } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OutboundSIMExchangeStatusService extends RestDataSource<OutboundSIMExchangeStatus> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(
      httpClient,
      BackendApi.outboundSIMExchangeStatusApi,
      errorHandlerService,
      false
    );
  }

  addUpdate(
    outboundSIMExchangeStatus: OutboundSIMExchangeStatus
  ): Observable<OutboundSIMExchangeStatus | null> {
    if (outboundSIMExchangeStatus?.id) {
      const outboundSIMExchangeStatusToBeEdited = {
        ...outboundSIMExchangeStatus,
      };

      outboundSIMExchangeStatusToBeEdited.status =
        outboundSIMExchangeStatus.status.trim();
      outboundSIMExchangeStatusToBeEdited.version =
        outboundSIMExchangeStatus.version;

      return this.update(
        outboundSIMExchangeStatus.id,
        outboundSIMExchangeStatusToBeEdited
      );
    } else {
      const outboundSIMExchangeStatusToBeAdded = {
        status: outboundSIMExchangeStatus.status.trim(),
        version: outboundSIMExchangeStatus.version,
      };

      return this.add(outboundSIMExchangeStatusToBeAdded);
    }
  }
}
