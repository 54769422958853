import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GridMenuItem, Column } from '@bilateral-roaming/core/models';
@Component({
  selector: 'app-list-master',
  templateUrl: './list-master.component.html',
  styleUrls: ['./list-master.component.scss'],
})
export class ListMasterComponent {
  @Input() modelData = [];
  @Input() menuItems: GridMenuItem[] = [];
  @Input() tableColumns: Column[] = [];
  @Input() paginationTitle: string = '';
  @Input() showRefreshButton = true;
  @Input() showAddButton = true;
  @Input() showMenu = true;
  @Input() lazyLoad = false;
  @Input() loading = false;
  @Input() showEmptyMessage: boolean = false;
  @Input() totalRecords!: number;
  @Input() addPermissions: string[] = [];
  @Input() domoPermissions: string[] = [];
  @Input() scrollable: boolean = false;
  @Input() scrollHeight!: string;
  @Input() paginator: boolean = false;
  @Input() showStatusColumn: boolean = true;
  @Input() enableSelection = true;
  @Input() virtualScroll: boolean = false;
  @Input() virtualScrollItemSize!: number;
  @Input() showCSV: boolean = false;
  @Input() showExcel: boolean = false;
  @Input() showPDF: boolean = false;
  @Input() showSendToDomo: boolean = false;
  @Input() sendToDomoCallback: Function = () => {};

  @Output() sendToDomoClicked = new EventEmitter();
  @Output() refreshClicked = new EventEmitter();
  @Output() addClicked = new EventEmitter();
  @Output() rowClicked = new EventEmitter<{ data: any }>();
  @Output() lazyLoadChanged = new EventEmitter();
  @Output() menuItemClicked = new EventEmitter<{
    menuItem: GridMenuItem;
    data: any;
  }>();

  constructor() {}

  ngOnInit(): void {}
}
