<app-table-grid
  [tableColumns]="tableColumns"
  [dataSource]="modelData"
  [paginationTitle]="paginationTitle"
  [showRefreshButton]="showRefreshButton"
  [showAddButton]="showAddButton"
  [showMenu]="showMenu"
  [menuItems]="menuItems"
  [addPermissions]="addPermissions"
  [domoPermissions]="domoPermissions"
  [lazyLoad]="lazyLoad"
  [loading]="loading"
  [showEmptyMessage]="showEmptyMessage"
  [scrollable]="scrollable"
  [scrollHeight]="scrollHeight"
  [totalRecords]="totalRecords"
  [paginator]="paginator"
  (addClicked)="addClicked.emit()"
  (rowClicked)="rowClicked.emit($event)"
  (menuItemClicked)="menuItemClicked.emit($event)"
  (refreshClicked)="refreshClicked.emit()"
  (lazyLoadChanged)="lazyLoadChanged.emit($event)"
  (sendToDomoClicked)="sendToDomoClicked.emit()"
  [showStatusColumn]="showStatusColumn"
  [enableSelection]="enableSelection"
  [virtualScroll]="virtualScroll"
  [virtualScrollItemSize]="virtualScrollItemSize"
  [showCSV]="showCSV"
  [showExcel]="showExcel"
  [showPDF]="showPDF"
  [showSendToDomo]="showSendToDomo"
></app-table-grid>
