import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { CommercialDatasetId } from '@bilateral-roaming/config/domo';

import { CommercialData } from '../models';
import { catchError, map, tap } from 'rxjs/operators';
import { MessagesService } from './messages.service';

@Injectable({
  providedIn: 'root',
})
export class CommercialDataService extends RestDataSource<CommercialData> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService,
    private messagesService: MessagesService
  ) {
    super(httpClient, BackendApi.commercialApi, errorHandlerService, false);
  }

  publisToDomo() {
    let data = {};
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.httpClient
      .post(`${this.baseUrl}/publish/${CommercialDatasetId}`, data, {
        headers: header,
      })
      .pipe(
        catchError((err: any) => this.errorHandlerService.handleError(err)),
        map((data: any) => {
          if (data.code == 200) {
            this.messagesService.showSuccessMessage(data.message);
          } else {
            this.messagesService.showErrorMessage(data.mesage);
          }
        })
      )
      .subscribe();
  }

  getAllFiltered(commercialData: CommercialData) {
    return this.httpClient
      .get(
        `${this.baseUrl}/all?regionId=${
          commercialData.regionId || ''
        }&countryId=${commercialData.countryId || ''}`
      )
      .pipe(
        catchError((err: any) => this.errorHandlerService.handleError(err)),
        map((data: any) => data?.result),
        map((data: any) =>
          this.filterData && data ? data.filter((d: any) => d.active) : data
        ), // Filter data based on its active flag
        map((data: any) => {
          return data.map((item: any) => ({ ...item, inactive: !item.active }));
        }),
        tap((data) => console.log(data))
      );
  }

  addUpdate(commercialData: CommercialData): Observable<CommercialData | null> {
    if (commercialData?.id) {
      const commercialDataToBeEdited = { ...commercialData };

      // commercialDataToBeEdited.name = commercialData.name.trim();
      commercialDataToBeEdited.version = commercialData.version;
      return this.update(commercialData.id, commercialDataToBeEdited);
    } else {
      const commercialDataToBeAdded = {
        ...commercialData,
        version: commercialData.version,
      };
      return this.add(commercialDataToBeAdded);
    }
  }

  addUpdateCompared(
    prevCommercialData: CommercialData,
    newCommercialData: CommercialData
  ): Observable<CommercialData | null> {
    if (prevCommercialData == null) {
      // Not previous
      return this.addUpdate(newCommercialData);
    }
    let myPayload: any = {};
    let x: any = { ...prevCommercialData };
    let y: any = { ...newCommercialData };
    for (let k in prevCommercialData) {
      if (x[k] != y[k]) {
        myPayload[k] = y[k];
      }
      if (x[k]?.id) {
        if (y[`${k}Id`] && y[`${k}Id`] != x[k]?.id) {
          myPayload[`${k}Id`] = y[`${k}Id`];
        }
      }
    }
    return this.update(prevCommercialData.id, myPayload);
  }
}
