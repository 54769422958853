import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { CommercialDataArchived } from '../models';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommercialDataArchivedService extends RestDataSource<CommercialDataArchived> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(
      httpClient,
      BackendApi.commercialArchivedApi,
      errorHandlerService,
      false
    );
  }

  getAllById(id: string): Observable<CommercialDataArchived[]> {
    return this.httpClient.get(`${this.baseUrl}/main/${id}`).pipe(
      catchError((err: any) => this.errorHandlerService.handleError(err)),
      map((data: any) => data?.result),
      map((data: any) =>
        this.filterData && data ? data.filter((d: any) => d.active) : data
      ), // Filter data based on its active flag
      tap((data) => console.log(data))
    );
  }
}
