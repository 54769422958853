import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PIC } from '../models/pics';
import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';

@Injectable({
  providedIn: 'root',
})
export class TechnicalPICsService extends RestDataSource<PIC> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.technicalPICsApi, errorHandlerService, false);
  }

  addUpdate(pic: PIC): Observable<PIC | null> {
    if (pic?.id) {
      const picToBeEdited = { ...pic };

      picToBeEdited.name = pic.name.trim();
      picToBeEdited.version = pic.version;

      return this.update(pic.id, picToBeEdited);
    } else {
      const picToBeAdded = {
        name: pic.name.trim(),
        version: pic.version,
      };

      return this.add(picToBeAdded);
    }
  }
}
