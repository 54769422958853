import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class MockedKeycloakService extends KeycloakService {
  override init() {
    return Promise.resolve(true);
  }

  override getKeycloakInstance() {
    return {
      loadUserInfo: () => {
        let callback: any;
        Promise.resolve().then(() => {
          callback({
            userName: 'name',
          });
        });
        return {
          success: (fn: any) => (callback = fn),
        };
      },
    } as any;
  }

  override isLoggedIn(): Promise<boolean> {
    return Promise.resolve(true);
  }
}
