import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

export abstract class RestDataSource<T> {
  constructor(
    protected httpClient: HttpClient,
    protected baseUrl: string,
    protected errorHandlerService: ErrorHandlerService,
    protected filterData = true
  ) {}

  getAllActive(): Observable<T[]> {
    return this.httpClient.get(`${this.baseUrl}/all`).pipe(
      catchError((err: any) => this.errorHandlerService.handleError(err)),
      map((data: any) => data?.result),
      map((data: any) => (data ? data.filter((d: any) => d.active) : data)), // Filter data based on its active flag
      tap((data) => console.log(data))
    );
  }

  getAll(): Observable<T[]> {
    return this.httpClient.get(`${this.baseUrl}/all`).pipe(
      catchError((err: any) => this.errorHandlerService.handleError(err)),
      map((data: any) => data?.result),
      map((data: any) =>
        this.filterData && data ? data.filter((d: any) => d.active) : data
      ), // Filter data based on its active flag
      map((data: any) => {
        return data.map((item: any) => ({ ...item, inactive: !item.active }));
      }),
      tap((data) => console.log(data))
    );
  }

  getById(id: string): Observable<T> {
    return this.httpClient.get(`${this.baseUrl}/${id}`).pipe(
      catchError((err: any) => this.errorHandlerService.handleError(err)),
      map((data: any) => data?.result),
      tap((data) => console.log(data))
    );
  }

  add(entityToBeAdded: Partial<T> | any): Observable<null> {
    return this.httpClient.post(`${this.baseUrl}/new`, entityToBeAdded).pipe(
      catchError((err: any) => this.errorHandlerService.handleError(err)),
      map((data: any) => data?.result),
      tap((data) => console.log(data))
    );
  }

  update(id: string, entityToBeUpdated: Partial<T> | any): Observable<null> {
    return this.httpClient
      .put(`${this.baseUrl}/${id}/update`, entityToBeUpdated)
      .pipe(
        catchError((err: any) => this.errorHandlerService.handleError(err)),
        map((data: any) => data?.result),
        tap((data) => console.log(data))
      );
  }

  delete(id: string, status?: boolean): Observable<null> {
    return this.httpClient
      .put(`${this.baseUrl}/status/${id}/update`, {
        active: status,
      })
      .pipe(
        catchError((err: any) => this.errorHandlerService.handleError(err)),
        map((data: any) => data?.result),
        tap((data) => console.log(data))
      );
  }
}
