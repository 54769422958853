export interface Column {
  columnDef: string;
  header: string;
  cell: Function;
  changeColor?: Function;
  options?: any[];
  optionLabel?: string;
  isLink?: boolean;
  isButton?: boolean;
  isIcon?: boolean;
  url?: string;
  cssClass?: string;
  sticky?: boolean;
  stickyEnd?: boolean;
  sortingConfig?: { enabled: boolean };
  filteringConfig?: { enabled: boolean; type?: 'text' | 'numeric' | 'date' };
  highlight?: boolean;
}
