import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { IRAStatus } from '../models';

@Injectable({
  providedIn: 'root',
})
export class IRAStatusService extends RestDataSource<IRAStatus> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.iraStatusApi, errorHandlerService, false);
  }

  addUpdate(iraStatus: IRAStatus): Observable<IRAStatus | null> {
    if (iraStatus?.id) {
      const iraStatusToBeEdited = { ...iraStatus };

      iraStatusToBeEdited.status = iraStatus.status.trim();
      iraStatusToBeEdited.version = iraStatus.version;

      return this.update(iraStatus.id, iraStatusToBeEdited);
    } else {
      const iraStatusToBeAdded = {
        ...iraStatus,
        status: iraStatus.status.trim(),
        version: iraStatus.version,
      };

      return this.add(iraStatusToBeAdded);
    }
  }
}
