import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { DIOTStatus } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DIOTStatusService extends RestDataSource<DIOTStatus> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.diotStatusApi, errorHandlerService, false);
  }

  addUpdate(diotStatus: DIOTStatus): Observable<DIOTStatus | null> {
    if (diotStatus?.id) {
      const diotStatusToBeEdited = { ...diotStatus };

      diotStatusToBeEdited.status = diotStatus.status.trim();
      diotStatusToBeEdited.version = diotStatus.version;

      return this.update(diotStatus.id, diotStatusToBeEdited);
    } else {
      const diotStatusToBeAdded = {
        ...diotStatus,
        status: diotStatus.status.trim(),
        version: diotStatus.version,
      };

      return this.add(diotStatusToBeAdded);
    }
  }
}
