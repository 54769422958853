import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { TechnicalData } from '../models';
import { TechnicalDatasetId } from '@bilateral-roaming/config/domo';
import { catchError, map } from 'rxjs/operators';
import { MessagesService } from './messages.service';

@Injectable({
  providedIn: 'root',
})
export class TechnicalDataService extends RestDataSource<TechnicalData> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService,
    private messagesService: MessagesService
  ) {
    super(httpClient, BackendApi.technicalApi, errorHandlerService, false);
  }

  publisToDomo() {
    let data = {};
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.httpClient
      .post(`${this.baseUrl}/publish/${TechnicalDatasetId}`, data, {
        headers: header,
      })
      .pipe(
        catchError((err: any) => this.errorHandlerService.handleError(err)),
        map((data: any) => {
          if (data.code == 200) {
            this.messagesService.showSuccessMessage(data.message);
          } else {
            this.messagesService.showErrorMessage(data.mesage);
          }
        })
      )
      .subscribe();
  }

  addUpdate(technicalData: TechnicalData): Observable<TechnicalData | null> {
    if (technicalData?.id) {
      const technicalDataToBeEdited = { ...technicalData };

      technicalDataToBeEdited.version = technicalData.version;
      console.log(technicalDataToBeEdited);
      return this.update(technicalData.id, technicalDataToBeEdited);
    } else {
      const technicalDataToBeAdded = {
        ...technicalData,
        version: technicalData.version,
      };
      console.log(technicalDataToBeAdded);
      return this.add(technicalDataToBeAdded);
    }
  }

  addUpdateCompared(
    prevTechnicalData: TechnicalData,
    newTechnicalData: TechnicalData
  ): Observable<TechnicalData | null> {
    if (prevTechnicalData == null) {
      // Not previous
      return this.addUpdate(newTechnicalData);
    }
    let myPayload: any = {};
    let x: any = { ...prevTechnicalData };
    let y: any = { ...newTechnicalData };
    for (let k in prevTechnicalData) {
      if (x[k] != y[k]) {
        myPayload[k] = y[k];
      }
      if (x[k]?.id) {
        if (y[`${k}Id`] && y[`${k}Id`] != x[k]?.id) {
          myPayload[`${k}Id`] = y[`${k}Id`];
        }
      }
    }
    return this.update(prevTechnicalData.id, myPayload);
  }
}
