import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { Priority } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PrioritiesService extends RestDataSource<Priority> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.prioritiesApi, errorHandlerService, false);
  }

  addUpdate(priority: Priority): Observable<Priority | null> {
    if (priority?.id) {
      const priorityToBeEdited = { ...priority };

      priorityToBeEdited.name = priority.name.trim();
      priorityToBeEdited.version = priority.version;

      return this.update(priority.id, priorityToBeEdited);
    } else {
      const priorityToBeAdded = {
        name: priority.name.trim(),
        version: priority.version,
      };

      return this.add(priorityToBeAdded);
    }
  }
}
