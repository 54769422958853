import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url ===
      'https://stg-rcpauth.rmb-lab.jp/auth/realms/Rakuten-OSS-Stg/protocol/openid-connect/userinfo'
    ) {
      return next.handle(request); // Skip the header injection for this URL
    }

    const modifiedRequest = request.clone({
      setHeaders: {
        audience: 'apim',
      },
    });

    return next.handle(modifiedRequest);
  }
}
