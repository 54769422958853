import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Authorities } from './config';
import { NotAuthorizedComponent } from './shared/components';
import { AuthGuard } from './core/guards';

const routes: Routes = [
  { path: '', redirectTo: 'master-data', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    data: {
      breadcrumb: 'Dashboard',
      roles: [Authorities.DEFAULT],
    },
    // canActivate: [AuthGuard],
  },
  {
    path: 'master-data',
    loadChildren: () =>
      import('./modules/master-data/master-data.module').then(
        (m) => m.MasterDataModule
      ),
    data: {
      breadcrumb: 'Master Data',
      roles: [Authorities.DEFAULT],
    },
    // canActivate: [AuthGuard],
  },
  {
    path: 'technical-data',
    loadChildren: () =>
      import('./modules/technical-data/technical-data.module').then(
        (m) => m.TechnicalDataModule
      ),
    data: {
      breadcrumb: 'Technical Report',
      roles: [Authorities.DEFAULT],
    },
    // canActivate: [AuthGuard],
  },
  {
    path: 'commercial-data',
    loadChildren: () =>
      import('./modules/commercial-data/commercial-data.module').then(
        (m) => m.CommercialDataModule
      ),
    data: {
      breadcrumb: 'Commercial Agreement',
      roles: [Authorities.DEFAULT],
    },
    // canActivate: [AuthGuard],
  },
  {
    path: 'advanced-analytics',
    loadChildren: () =>
      import('./modules/advanced-analytics/advanced-analytics.module').then(
        (m) => m.AdvancedAnalyticsModule
      ),
    data: {
      breadcrumb: 'Advanced Analytics',
      roles: [Authorities.DEFAULT],
    },
    // canActivate: [AuthGuard],
  },
  {
    path: 'data-history',
    loadChildren: () =>
      import('./modules/data-history/data-history.module').then(
        (m) => m.DataHistoryModule
      ),
    data: {
      breadcrumb: 'Data History',
      roles: [Authorities.DEFAULT],
    },
    // canActivate: [AuthGuard],
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
    // canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
