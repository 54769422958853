export interface GridMenuItem {
  label: MenuItem;
  icon: string;
  permissions: string[];
}

export enum MenuItem {
  Edit = 'Edit',
  Delete = 'Delete',
  History = 'History',
}
