import { Audit } from './audit';
import { BaseEntity } from './base-entity';
import { Country } from './countries';
import { Operator } from './operators';

export interface CountryOperator extends BaseEntity, Audit {
  countryId: string;
  country: Country;
  operatorId: string;
  operator: Operator;
}
