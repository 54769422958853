import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from 'lodash';

@Component({
  selector: 'app-details-master',
  templateUrl: './details-master.component.html',
  styleUrls: ['./details-master.component.scss'],
})
export class DetailsMasterComponent implements OnInit {
  detailsData: any[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    const entity = { ...this.data.details };

    data.detailsColumn.map((columnName: any) => {
      const value = _.get(entity, columnName);

      this.detailsData.push(value);
    });
  }

  ngOnInit(): void {}
}
