<div
  class="
    m-20
    flex flex-col
    space-y-4
    text-center
    items-center
    justify-center
    text-sm
    {{ extraClasses }}
  "
>
  <span>{{ message }}</span>

  <ng-container *ngxPermissionsOnly="permissions">
    <button
      class="flex text-sm cursor-pointer border-none text-purple-600 items-center"
      *ngIf="showAdd"
      (click)="addAction.emit()"
    >
      <mat-icon>add</mat-icon> {{ addText }}
    </button></ng-container
  >
</div>
