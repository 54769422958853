import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { OperatorStatus } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OperatorStatusService extends RestDataSource<OperatorStatus> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.operatorStatusApi, errorHandlerService, false);
  }

  addUpdate(operatorStatus: OperatorStatus): Observable<OperatorStatus | null> {
    if (operatorStatus?.id) {
      const operatorStatusToBeEdited = { ...operatorStatus };

      operatorStatusToBeEdited.status = operatorStatus.status.trim();
      operatorStatusToBeEdited.score = operatorStatus.score;
      operatorStatusToBeEdited.version = operatorStatus.version;

      return this.update(operatorStatus.id, operatorStatusToBeEdited);
    } else {
      const operatorStatusToBeAdded = {
        status: operatorStatus.status.trim(),
        score: operatorStatus.score,
        version: operatorStatus.version,
      };

      return this.add(operatorStatusToBeAdded);
    }
  }
}
