import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from './angular-material.module';
import { PrimeNgModule } from './prime-ng.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/core/interceptors/auth.interceptor';
import { CoreModule } from 'src/app/core/core.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastrModule } from 'ngx-toastr';
import { BadgeModule } from 'primeng/badge';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MockedKeycloakService } from './core/services';
import { environment } from '@environment';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak
      .init({
        config: {
          realm: 'Rakuten-OSS-Stg',
          url: 'https://stg-rcpauth.rmb-lab.jp/auth/',
          clientId: 'bilr-staging-client',
        } as any,
        initOptions: {
          checkLoginIframe: false,
          onLoad: 'login-required',
        },
      })
      .catch((error) => {
        console.error('Keycloak login failed ' + error);
      });
}

export let myKeycloakFactory = () => {
  return environment.UseKeycloak
    ? new KeycloakService()
    : new MockedKeycloakService();
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    OverlayPanelModule,
    AngularMaterialModule,
    PrimeNgModule,
    BrowserAnimationsModule,
    HttpClientModule,
    KeycloakAngularModule,
    ToastrModule.forRoot({
      closeButton: true,
    }),
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    // Local: Uncomment me for local testing
    {
      provide: KeycloakService,
      // useClass: MockedKeycloakService,
      useFactory: myKeycloakFactory,
    },
    // Rakuten: Uncomment me for deploying
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
