import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Zone } from '../models/zones';
import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';

@Injectable({
  providedIn: 'root',
})
export class ZonesService extends RestDataSource<Zone> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.zonesApi, errorHandlerService, false);
  }

  addUpdate(zone: Zone): Observable<Zone | null> {
    if (zone?.id) {
      const zoneToBeEdited = { ...zone };

      zoneToBeEdited.name = zone.name.trim();
      zoneToBeEdited.version = zone.version;

      return this.update(zone.id, zoneToBeEdited);
    } else {
      const zoneToBeAdded = {
        name: zone.name.trim(),
        version: zone.version,
      };

      return this.add(zoneToBeAdded);
    }
  }
}
