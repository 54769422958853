export const testingAuthorities = [
  'ROLE_BILR_CREATE_COMMERCIAL_STATUS',
  'ROLE_BILR_LIST_ALL_COMMERCIAL_STATUS',
  'ROLE_BILR_LIST_SPECIFIC_COMMERCIAL_STATUS',
  'ROLE_BILR_UPDATE_COMMERCIAL_STATUS',
  'ROLE_BILR_UPDATE_STATUS_COMMERCIAL_STATUS',
  'ROLE_BILR_CREATE_CONTRACTING_PARTY',
  'ROLE_BILR_LIST_ALL_CONTRACTING_PARTY',
  'ROLE_BILR_LIST_SPECIFIC_CONTRACTING_PARTY',
  'ROLE_BILR_UPDATE_CONTRACTING_PARTY',
  'ROLE_BILR_UPDATE_STATUS_CONTRACTING_PARTY',
  'ROLE_BILR_CREATE_COUNTRY',
  'ROLE_BILR_LIST_ALL_COUNTRY',
  'ROLE_BILR_LIST_SPECIFIC_COUNTRY',
  'ROLE_BILR_UPDATE_COUNTRY',
  'ROLE_BILR_UPDATE_STATUS_COUNTRY',
  'ROLE_BILR_CREATE_DIOT_STATUS',
  'ROLE_BILR_LIST_ALL_DIOT_STATUS',
  'ROLE_BILR_LIST_SPECIFIC_DIOT_STATUS',
  'ROLE_BILR_UPDATE_DIOT_STATUS',
  'ROLE_BILR_UPDATE_STATUS_DIOT_STATUS',
  'ROLE_BILR_CREATE_GROUP',
  'ROLE_BILR_LIST_ALL_GROUP',
  'ROLE_BILR_LIST_SPECIFIC_GROUP',
  'ROLE_BILR_UPDATE_GROUP',
  'ROLE_BILR_UPDATE_STATUS_GROUP',
  'ROLE_BILR_CREATE_INBOUND_SIM_EXCHANGE_STATUS',
  'ROLE_BILR_LIST_ALL_INBOUND_SIM_EXCHANGE_STATUS',
  'ROLE_BILR_LIST_SPECIFIC_INBOUND_SIM_EXCHANGE_STATUS',
  'ROLE_BILR_UPDATE_INBOUND_SIM_EXCHANGE_STATUS',
  'ROLE_BILR_UPDATE_STATUS_INBOUND_SIM_EXCHANGE_STATUS',
  'ROLE_BILR_CREATE_IRA_STATUS',
  'ROLE_BILR_LIST_ALL_IRA_STATUS',
  'ROLE_BILR_LIST_SPECIFIC_IRA_STATUS',
  'ROLE_BILR_UPDATE_IRA_STATUS',
  'ROLE_BILR_UPDATE_STATUS_IRA_STATUS',
  'ROLE_BILR_CREATE_MIC_STATUS',
  'ROLE_BILR_LIST_ALL_MIC_STATUS',
  'ROLE_BILR_LIST_SPECIFIC_MIC_STATUS',
  'ROLE_BILR_UPDATE_MIC_STATUS',
  'ROLE_BILR_UPDATE_STATUS_MIC_STATUS',
  'ROLE_BILR_CREATE_OPERATOR',
  'ROLE_BILR_LIST_ALL_OPERATOR',
  'ROLE_BILR_LIST_SPECIFIC_OPERATOR',
  'ROLE_BILR_UPDATE_OPERATOR',
  'ROLE_BILR_UPDATE_STATUS_OPERATOR',
  'ROLE_BILR_CREATE_OPERATOR_STATUS',
  'ROLE_BILR_LIST_ALL_OPERATOR_STATUS',
  'ROLE_BILR_LIST_SPECIFIC_OPERATOR_STATUS',
  'ROLE_BILR_UPDATE_OPERATOR_STATUS',
  'ROLE_BILR_UPDATE_STATUS_OPERATOR_STATUS',
  'ROLE_BILR_CREATE_OUTBOUND_SIM_EXCHANGE_STATUS',
  'ROLE_BILR_LIST_ALL_OUTBOUND_SIM_EXCHANGE_STATUS',
  'ROLE_BILR_LIST_SPECIFIC_OUTBOUND_SIM_EXCHANGE_STATUS',
  'ROLE_BILR_UPDATE_OUTBOUND_SIM_EXCHANGE_STATUS',
  'ROLE_BILR_UPDATE_STATUS_OUTBOUND_SIM_EXCHANGE_STATUS',
  'ROLE_BILR_CREATE_PIC',
  'ROLE_BILR_LIST_ALL_PIC',
  'ROLE_BILR_LIST_SPECIFIC_PIC',
  'ROLE_BILR_UPDATE_PIC',
  'ROLE_BILR_UPDATE_STATUS_PIC',
  'ROLE_BILR_CREATE_TECHNICAL_PIC',
  'ROLE_BILR_LIST_ALL_TECHNICAL_PIC',
  'ROLE_BILR_LIST_SPECIFIC_TECHNICAL_PIC',
  'ROLE_BILR_UPDATE_TECHNICAL_PIC',
  'ROLE_BILR_UPDATE_STATUS_TECHNICAL_PIC',
  'ROLE_BILR_CREATE_COMMERCIAL_PIC',
  'ROLE_BILR_LIST_ALL_COMMERCIAL_PIC',
  'ROLE_BILR_LIST_SPECIFIC_COMMERCIAL_PIC',
  'ROLE_BILR_UPDATE_COMMERCIAL_PIC',
  'ROLE_BILR_UPDATE_STATUS_COMMERCIAL_PIC',
  'ROLE_BILR_CREATE_REGION_COMMERCIAL_PIC',
  'ROLE_BILR_LIST_ALL_REGION_COMMERCIAL_PIC',
  'ROLE_BILR_LIST_SPECIFIC_REGION_COMMERCIAL_PIC',
  'ROLE_BILR_UPDATE_REGION_COMMERCIAL_PIC',
  'ROLE_BILR_UPDATE_STATUS_REGION_COMMERCIAL_PIC',
  'ROLE_BILR_CREATE_PRIORITY',
  'ROLE_BILR_LIST_ALL_PRIORITY',
  'ROLE_BILR_LIST_SPECIFIC_PRIORITY',
  'ROLE_BILR_UPDATE_PRIORITY',
  'ROLE_BILR_UPDATE_STATUS_PRIORITY',
  'ROLE_BILR_CREATE_REGION',
  'ROLE_BILR_LIST_ALL_REGION',
  'ROLE_BILR_LIST_SPECIFIC_REGION',
  'ROLE_BILR_UPDATE_REGION',
  'ROLE_BILR_UPDATE_STATUS_REGION',
  'ROLE_BILR_CREATE_RESELL_CLAUSE_STATUS',
  'ROLE_BILR_LIST_ALL_RESELL_CLAUSE_STATUS',
  'ROLE_BILR_LIST_SPECIFIC_RESELL_CLAUSE_STATUS',
  'ROLE_BILR_UPDATE_RESELL_CLAUSE_STATUS',
  'ROLE_BILR_UPDATE_STATUS_RESELL_CLAUSE_STATUS',
  'ROLE_BILR_CREATE_ROLLOUT_STATUS',
  'ROLE_BILR_LIST_ALL_ROLLOUT_STATUS',
  'ROLE_BILR_LIST_SPECIFIC_ROLLOUT_STATUS',
  'ROLE_BILR_UPDATE_ROLLOUT_STATUS',
  'ROLE_BILR_UPDATE_STATUS_ROLLOUT_STATUS',
  'ROLE_BILR_CREATE_TADIG',
  'ROLE_BILR_LIST_ALL_TADIG',
  'ROLE_BILR_LIST_SPECIFIC_TADIG',
  'ROLE_BILR_UPDATE_TADIG',
  'ROLE_BILR_UPDATE_STATUS_TADIG',
  'ROLE_BILR_CREATE_ZONE',
  'ROLE_BILR_LIST_ALL_ZONE',
  'ROLE_BILR_LIST_SPECIFIC_ZONE',
  'ROLE_BILR_UPDATE_ZONE',
  'ROLE_BILR_UPDATE_STATUS_ZONE',
  'ROLE_BILR_CREATE_ROAMING_AGREEMENT_COMMERCIALS',
  'ROLE_BILR_LIST_ALL_ROAMING_AGREEMENT_COMMERCIALS',
  'ROLE_BILR_LIST_SPECIFIC_ROAMING_AGREEMENT_COMMERCIALS',
  'ROLE_BILR_UPDATE_ROAMING_AGREEMENT_COMMERCIALS',
  'ROLE_BILR_UPDATE_STATUS_ROAMING_AGREEMENT_COMMERCIALS',
  'ROLE_BILR_PUBLISH_ROAMING_AGREEMENT_COMMERCIALS',
  'ROLE_BILR_CREATE_TESTING_STATUS_TECHNICALS',
  'ROLE_BILR_LIST_ALL_TESTING_STATUS_TECHNICALS',
  'ROLE_BILR_LIST_SPECIFIC_TESTING_STATUS_TECHNICALS',
  'ROLE_BILR_UPDATE_TESTING_STATUS_TECHNICALS',
  'ROLE_BILR_UPDATE_STATUS_TESTING_STATUS_TECHNICALS',
  'ROLE_BILR_PUBLISH_TESTING_STATUS_TECHNICALS',
  'ROLE_BILR_LIST_ALL_ROAMING_AGREEMENT_COMMERCIALS_ARCHIVE',
  'ROLE_BILR_LIST_SPECIFIC_ROAMING_AGREEMENT_COMMERCIALS_ARCHIVE',
  'ROLE_BILR_LIST_ROAMING_AGREEMENT_COMMERCIALS_ARCHIVE_BY_MAIN_ID',
  'ROLE_BILR_COMPARE_ROAMING_AGREEMENT_COMMERCIALS_ARCHIVE',
  'ROLE_BILR_LIST_ALL_TESTING_STATUS_TECHNICALS_ARCHIVE',
  'ROLE_BILR_LIST_SPECIFIC_TESTING_STATUS_TECHNICALS_ARCHIVE',
  'ROLE_BILR_LIST_TESTING_STATUS_TECHNICALS_ARCHIVE_BY_MAIN_ID',
  'ROLE_BILR_COMPARE_TESTING_STATUS_TECHNICALS_ARCHIVE',
  'ROLE_FEAT_BILR_MASTER_DATA_canView',
  'ROLE_FEAT_BILR_COMMERCIAL_DATA_canView',
  'ROLE_FEAT_BILR_TECHNICAL_DATA_canView',
];
