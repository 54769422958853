import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { ResellClauseStatus } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ResellClauseStatusService extends RestDataSource<ResellClauseStatus> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(
      httpClient,
      BackendApi.resellClausesStatusApi,
      errorHandlerService,
      false
    );
  }

  addUpdate(
    resellClausesStatus: ResellClauseStatus
  ): Observable<ResellClauseStatus | null> {
    if (resellClausesStatus?.id) {
      const resellClausesStatusToBeEdited = { ...resellClausesStatus };

      resellClausesStatusToBeEdited.status = resellClausesStatus.status.trim();
      resellClausesStatusToBeEdited.version = resellClausesStatus.version;

      return this.update(resellClausesStatus.id, resellClausesStatusToBeEdited);
    } else {
      const resellClausesStatusToBeAdded = {
        status: resellClausesStatus.status.trim(),
        version: resellClausesStatus.version,
      };

      return this.add(resellClausesStatusToBeAdded);
    }
  }
}
