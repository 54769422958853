import { Audit } from './audit';
import { BaseEntity } from './base-entity';
import { Region } from './regions';
import { Zone } from './zones';

export interface Country extends BaseEntity, Audit {
  regionId: string;
  regionName: string;
  region: Region;
  zoneId: string;
  zoneName: string;
  zone: Zone;
  tomiaCountryNaming: string;
  rmiCountryNaming: string;
}
