import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RolloutStatus } from '../models';
import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';

@Injectable({
  providedIn: 'root',
})
export class RolloutStatusService extends RestDataSource<RolloutStatus> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.rolloutStatusApi, errorHandlerService, false);
  }

  addUpdate(rolloutStatus: RolloutStatus): Observable<RolloutStatus | null> {
    if (rolloutStatus?.id) {
      const rolloutStatusToBeEdited = { ...rolloutStatus };

      rolloutStatusToBeEdited.status = rolloutStatus.status.trim();
      rolloutStatusToBeEdited.operatorStatusId = rolloutStatus.operatorStatusId;
      rolloutStatusToBeEdited.version = rolloutStatus.version;

      return this.update(rolloutStatus.id, rolloutStatusToBeEdited);
    } else {
      const rolloutStatusToBeAdded = {
        status: rolloutStatus.status.trim(),
        version: rolloutStatus.version,
        operatorStatusId: rolloutStatus.operatorStatusId,
      };

      return this.add(rolloutStatusToBeAdded);
    }
  }
}
