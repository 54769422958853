import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { Tadig } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TadigsService extends RestDataSource<Tadig> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.tadigsApi, errorHandlerService, false);
  }

  addUpdate(tadig: Tadig): Observable<Tadig | null> {
    if (tadig?.id) {
      const tadigToBeEdited = { ...tadig };

      tadigToBeEdited.code = tadig.code.trim();
      tadigToBeEdited.ibDate = tadig.ibDate;
      tadigToBeEdited.obDate = tadig.obDate;
      tadigToBeEdited.version = tadig.version;

      return this.update(tadig.id, tadigToBeEdited);
    } else {
      const tadigToBeAdded = {
        code: tadig.code.trim(),
        ibDate: tadig.ibDate,
        obDate: tadig.obDate,
        version: tadig.version,
      };

      return this.add(tadigToBeAdded);
    }
  }
}
