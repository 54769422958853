import { environment } from '@environment';

const baseUrl = environment.BackendApi;

export const BackendApi = {
  picsApi: `${baseUrl}/lookups/pics`,
  zonesApi: `${baseUrl}/lookups/zones`,
  regionsApi: `${baseUrl}/lookups/regions`,
  operatorsApi: `${baseUrl}/lookups/operators`,
  countriesApi: `${baseUrl}/lookups/countries`,
  countriesOperatorsApi: `${baseUrl}/lookups/countries/operators`,
  prioritiesApi: `${baseUrl}/lookups/priority`,
  tadigsApi: `${baseUrl}/lookups/tadigs`,

  iraStatusApi: `${baseUrl}/lookups/status/ira`,
  micStatusApi: `${baseUrl}/lookups/status/mic`,
  diotStatusApi: `${baseUrl}/lookups/status/diot`,
  commercialStatusApi: `${baseUrl}/lookups/status/commercial`,
  resellClausesStatusApi: `${baseUrl}/lookups/status/resell/clauses`,
  contractingPartiesApi: `${baseUrl}/lookups/contractingparty`,
  groupsApi: `${baseUrl}/lookups/groups`,
  commercialPICsApi: `${baseUrl}/lookups/commercial/pics`,
  regionsCommercialPICsApi: `${baseUrl}/lookups/regions/pics`,

  operatorStatusApi: `${baseUrl}/lookups/status/operator`,
  rolloutStatusApi: `${baseUrl}/lookups/status/rollout`,
  inboundSIMExchangeStatusApi: `${baseUrl}/lookups/status/inbound/sim/exchange`,
  outboundSIMExchangeStatusApi: `${baseUrl}/lookups/status/outbound/sim/exchange`,
  technicalPICsApi: `${baseUrl}/lookups/technical/pics`,

  commercialApi: `${baseUrl}/roaming/agreement/commercials`,
  commercialArchivedApi: `${baseUrl}/roaming/agreement/commercials/archive`,

  technicalApi: `${baseUrl}/testing/status/technicals`,
  technicalArchivedApi: `${baseUrl}/testing/status/technicals/archive`,
};
