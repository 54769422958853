import { NgModule } from '@angular/core';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { Calendar } from 'primeng/calendar';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
@NgModule({
  exports: [
    TableModule,
    ButtonModule,
    MenuModule,
    PickListModule,
    OverlayPanelModule,
    BadgeModule,
    MultiSelectModule,
    DropdownModule,
    InputTextModule,
    DialogModule,
  ],
})
export class PrimeNgModule {
  constructor() {
    Calendar.prototype.getDateFormat = () => 'yy/mm/dd';
  }
}
