import { Component } from '@angular/core';
import { LayoutService } from './core/services';
import { ActivationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import {
  UserService,
  AuthService,
  UserNotificationsService,
} from './core/services';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Authorities, localStorageKeys } from './config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isLoggedIn = false;
  userProfile: KeycloakProfile | null = null;

  title = 'Bilateral Roaming';
  subTitle = '';
  showFiller = false;
  windowScrolled = false;
  breadcrumbRoutes = [
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'dashboard',
    //   permissions: [],
    // },
    // {
    //   name: 'Advanced Analytics',
    //   url: '/advanced-analytics',
    //   icon: 'bar_chart',
    //   permissions: [],
    // },
    {
      name: 'Master Data',
      url: '/master-data',
      icon: 'storage',
      permissions: [Authorities.ROLE_FEAT_BILR_MASTER_DATA_canView],
    },
    {
      name: 'Technical Report',
      url: '/technical-data',
      icon: 'people_outline',
      permissions: [Authorities.ROLE_FEAT_BILR_TECHNICAL_DATA_canView],
    },
    {
      name: 'Commercial Agreement',
      url: '/commercial-data',
      icon: 'inventory',
      permissions: [Authorities.ROLE_FEAT_BILR_COMMERCIAL_DATA_canView],
    },
  ];

  // private permissionsService: NgxPermissionsService,
  // private rolesService: NgxRolesService
  // public authService: AuthService
  constructor(
    private router: Router,
    public layoutService: LayoutService,
    public userService: UserService,
    private readonly keycloak: KeycloakService,
    public authService: AuthService,
    private permissionsService: NgxPermissionsService,
    public userNotificationsService: UserNotificationsService
  ) {}
  public async ngOnInit() {
    this.layoutService.setPrivateLayout();

    this.router.events
      .pipe(
        filter((e) => e instanceof ActivationEnd),
        distinctUntilChanged()
      )
      .subscribe((route: any) => {
        this.subTitle = route.snapshot.data.breadcrumb;
      });

    this.isLoggedIn = await this.keycloak.isLoggedIn();
    if (this.isLoggedIn) {
      this.authService.getUsername().subscribe((res) => {
        this.authService.setUsername(res.name);
      });
      this.authService.getCurrentUserAuthorities().subscribe((data) => {
        this.permissionsService.loadPermissions(data);
      });
      this.userNotificationsService.getAllUserNotifications(false);
      this.userNotificationsService.pollForNotifications();
    }

    this.keycloak.keycloakEvents$.subscribe((event) => {
      if (event.type === KeycloakEventType.OnAuthRefreshError) {
        this.logout();
      }
    });
  }

  scrollToTop(): void {
    const header = document.querySelector('#top');
    header?.scrollTo(0, 0);
  }

  onScroll(): void {
    const header = document.querySelector('#top');
    this.windowScrolled = header?.scrollTop! > 500;
  }

  public login() {
    this.keycloak.login();
  }

  public logout() {
    localStorage.removeItem(localStorageKeys.username);
    this.keycloak.logout();
  }
}
