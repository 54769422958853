import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { InboundSIMExchangeStatus } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InboundSIMExchangeStatusService extends RestDataSource<InboundSIMExchangeStatus> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(
      httpClient,
      BackendApi.inboundSIMExchangeStatusApi,
      errorHandlerService,
      false
    );
  }

  addUpdate(
    inboundSIMExchangeStatus: InboundSIMExchangeStatus
  ): Observable<InboundSIMExchangeStatus | null> {
    if (inboundSIMExchangeStatus?.id) {
      const inboundSIMExchangeStatusToBeEdited = {
        ...inboundSIMExchangeStatus,
      };

      inboundSIMExchangeStatusToBeEdited.status =
        inboundSIMExchangeStatus.status.trim();
      inboundSIMExchangeStatusToBeEdited.version =
        inboundSIMExchangeStatus.version;

      return this.update(
        inboundSIMExchangeStatus.id,
        inboundSIMExchangeStatusToBeEdited
      );
    } else {
      const inboundSIMExchangeStatusToBeAdded = {
        status: inboundSIMExchangeStatus.status.trim(),
        version: inboundSIMExchangeStatus.version,
      };

      return this.add(inboundSIMExchangeStatusToBeAdded);
    }
  }
}
