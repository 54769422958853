import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { MICStatus } from '../models';

@Injectable({
  providedIn: 'root',
})
export class MICStatusService extends RestDataSource<MICStatus> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.micStatusApi, errorHandlerService, false);
  }

  addUpdate(micStatus: MICStatus): Observable<MICStatus | null> {
    if (micStatus?.id) {
      const micStatusToBeEdited = { ...micStatus };

      micStatusToBeEdited.status = micStatus.status.trim();
      micStatusToBeEdited.version = micStatus.version;

      return this.update(micStatus.id, micStatusToBeEdited);
    } else {
      const micStatusToBeAdded = {
        ...micStatus,
        status: micStatus.status.trim(),
        version: micStatus.version,
      };

      return this.add(micStatusToBeAdded);
    }
  }
}
