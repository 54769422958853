import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Group } from '../models';
import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';

@Injectable({
  providedIn: 'root',
})
export class GroupsService extends RestDataSource<Group> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.groupsApi, errorHandlerService, false);
  }

  addUpdate(group: Group): Observable<Group | null> {
    if (group?.id) {
      const groupToBeEdited = { ...group };

      groupToBeEdited.name = group.name.trim();
      groupToBeEdited.version = group.version;

      return this.update(group.id, groupToBeEdited);
    } else {
      const groupToBeAdded = {
        name: group.name.trim(),
        version: group.version,
      };

      return this.add(groupToBeAdded);
    }
  }
}
