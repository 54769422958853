import { Audit } from './audit';
import { BaseEntity } from './base-entity';
import { Country } from './countries';
import { InboundSIMExchangeStatus } from './inbound-sim-exchange-status';
import { OperatorStatus } from './operator-status';
import { Operator } from './operators';
import { OutboundSIMExchangeStatus } from './outbound-sim-exchange-status';
import { PIC } from './pics';
import { Priority } from './priorities';
import { Region } from './regions';
import { RolloutStatus } from './rollout-status';
import { Tadig } from './tadigs';
import { Zone } from './zones';

export interface TechnicalData extends BaseEntity, Audit {
  regionId: string;
  region: Region;
  zoneId: string;
  zone: Zone;
  countryId: string;
  country: Country;
  operatorId: string;
  operator: Operator;
  tadigId: string;
  tadig: Tadig;
  priorityId: string;
  priority: Priority;
  oblaunchDate: string;
  ibLaunchDate: string;
  technicalPicId: string;
  technicalPic: PIC;
  outboundSimExchangeStatusId: string;
  outboundSimExchangeStatus: OutboundSIMExchangeStatus;
  internalVoiceId: string;
  internalVoice: RolloutStatus;
  internalDataId: string;
  internalData: RolloutStatus;
  internal4gLteId: string;
  internal4gLte: RolloutStatus;
  internal5gNsaId: string;
  internal5gNsa: RolloutStatus;
  internalVolteId: string;
  internalVolte: RolloutStatus;
  inboundSimExchangeStatusId: string;
  inboundSimExchangeStatus: InboundSIMExchangeStatus;
  internalIb4gLteId: string;
  internalIb4gLte: RolloutStatus;
  internalIb5gNsaId: string;
  internalIb5gNsa: RolloutStatus;
  internalIbVolteId: string;
  internalIbVolte: RolloutStatus;
  operatorStatusIbId: string;
  operatorStatusIb: OperatorStatus;
  operatorStatusObId: string;
  operatorStatusOb: OperatorStatus;
  internalVoiceOperatorStatus: string;
  voice: string;
  ibDate: string;
  obDate: string;
  // ibMaxScore: number;
  // obMaxScore: number;
}
