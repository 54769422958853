import { Audit } from './audit';
import { BaseEntity } from './base-entity';
import { CommercialStatus } from './commercial-status';
import { ContractingParty } from './contracting-party';
import { Country } from './countries';
import { DIOTStatus } from './diot-status';
import { Group } from './groups';
import { IRAStatus } from './ira-status';
import { MICStatus } from './mic-status';
import { Operator } from './operators';
import { PIC } from './pics';
import { Priority } from './priorities';
import { Region } from './regions';
import { RegionCommercialPIC } from './regions-commercial-pics';
import { ResellClauseStatus } from './resell-clauses-status';
import { Tadig } from './tadigs';
import { Zone } from './zones';

export interface CommercialData extends BaseEntity, Audit {
  obRank: number;
  obPercentage: number;
  ibRank: number;
  ibPercentage: number;
  regionId: string;
  regionName: string;
  region: Region;
  countryId: string;
  countryName: string;
  country: Country;
  countrySourceId: string;
  countrySourceName: string;
  zoneId: string;
  zone: Zone;
  zoneName: string;
  operatorId: string;
  operator: Operator;
  operatorName: string;
  commercialPicId: string;
  commercialPicName: string;
  commercialPic: PIC;
  regionCommercialPic: RegionCommercialPIC;
  marketShare: number;
  priorityId: string;
  priorityName: string;
  priority: Priority;
  tadigId: string;
  tadigName: string;
  tadig: Tadig;
  commercialStatusId: string;
  commercialStatusName: string;
  commercialStatus: CommercialStatus;
  bilateralDataRate: number;
  orangeRate: number;
  iraStatusId: string;
  iraStatusName: string;
  iraStatus: IRAStatus;
  micStatusId: string;
  micStatusName: string;
  micStatus: MICStatus;
  resellClauseStatusId: string;
  resellClauseStatusName: string;
  resellClauseStatus: ResellClauseStatus;
  m2mClause: string;
  permanentRoamingClause: string;
  group: Group;
  groupId: string;
  groupName: string;
  diotStatusId: string;
  diotStatusName: string;
  diotStatus: DIOTStatus;
  remark: string;
  technicalStatus: string;
  contractingPartyId: string;
  contractingParty: ContractingParty;
  legalStatus: string;
  outboundLaunchStatus: string;
}
