<mat-dialog-content>
  <div class="border-b-1 border-slate-200 bg-white">
    <div class="flex justify-between text-sm-15 p-4">
      <p class="font-rakuten-sans font-bold">{{ data.title }}</p>
      <button class="w-4 h-4 mx-2" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="bg-slate-100 p-4">
    <div class="bg-white border-1 border-slate-50 shadow-sm rounded-md mb-4">
      <div class="border-b-1 border-slate-200 p-2.5">
        <p class="text-sm font-bold">Details</p>
      </div>
      <div class="p-4">
        <div
          class="detail grid gap-y-5"
          [ngClass]="{
            'grid-cols-3 gap-x-10': detailsData.length >= 3,
            'grid-cols-2 gap-x-4': detailsData.length < 3
          }"
        >
          <div *ngFor="let item of detailsData; index as i">
            <span>{{ data.detailsColumnLabel[i] }}</span>
            <span class="break-all">{{ item?.toString() || "-" }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white border-1 border-slate-50 shadow-sm rounded-md">
      <div class="border-b-1 border-slate-200 p-2.5">
        <p class="text-sm font-bold">Audit</p>
      </div>
      <div class="p-4">
        <div class="detail grid-cols-3 grid gap-x-10 gap-y-5">
          <div>
            <span>Create Time</span>
            <span>{{
              data.details.createTime | date : "dd/MM/yyyy hh:mm a"
            }}</span>
          </div>

          <div>
            <span>Created By</span>
            <span>{{ data.details.createdBy || "-" }}</span>
          </div>

          <div>
            <span>Version</span>
            <span>{{ data.details.version }}</span>
          </div>

          <div>
            <span>Update Time</span>
            <span>{{
              data.details.updateTime | date : "dd/MM/yyyy hh:mm a"
            }}</span>
          </div>

          <div>
            <span>Updated By</span>
            <span>{{ data.details.updatedBy || "-" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
