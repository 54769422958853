import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { localStorageKeys } from './../../config/local-storage-keys';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userLoggedOut$!: Observable<boolean>;

  private userLoggedOutSubject = new BehaviorSubject(false);

  constructor(private router: Router) {
    this.userLoggedOut$ = this.userLoggedOutSubject.asObservable();
  }

  logout() {
    this.userLoggedOutSubject.next(true);
    localStorage.removeItem(localStorageKeys.authToken);
    localStorage.removeItem(localStorageKeys.username);
    this.router.navigate(['auth', 'login']);
  }
}
