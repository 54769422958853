<ng-container *ngIf="layoutService.layout$ | async as layout">
  <mat-toolbar
    *ngIf="layout.showHeader"
    class="flex justify-between bg-gray-50 border-b-1 border-gray-300 h-14"
  >
    <div class="flex items-center ml-5">
      <a class="h-14 w-14 -ml-9" routerLink="/">
        <img src="../assets/images/logo.png" alt="Rakuten" />
      </a>
      <button mat-icon-button class="text-lg" (click)="drawer.toggle()">
        <mat-icon> menu </mat-icon>
      </button>
      <div class="flex flex-col ml-3">
        <span class="text-md">Bilateral Roaming</span>
        <span class="text-xs font-normal">{{ subTitle }}</span>
      </div>
    </div>

    <div class="flex items-center text-sm mr-5">
      <ng-container *ngIf="authService.username$ | async as username">
        <span class="mr-4" *ngIf="username">Welcome, {{ username }}!</span>

        <!-- <ng-container
          *ngIf="
            userNotificationsService.notificationsData$
              | async as notificationsData
          "
        >
          <button
            class="mr-4"
            title="Notifications"
            (click)="op.toggle($event)"
          >
            <div
              *ngIf="notificationsData.unreadCount"
              pBadge
              [value]="notificationsData.unreadCount.toString()"
            >
              <mat-icon>notifications</mat-icon>
            </div>
            <div *ngIf="!notificationsData.unreadCount">
              <mat-icon>notifications</mat-icon>
            </div>
          </button>
          <p-overlayPanel
            #op
            class="shadow-lg"
            [style]="{
              width: '650px',
              'max-height': '550px',
              overflow: 'scroll',
              border: '1px solid #ccc'
            }"
          >
            <ng-template pTemplate>
              <div>
                <div
                  *ngIf="notificationsData.unreadCount"
                  class="flex text-right justify-end items-center bg-white h-3 p-5 border-b-1 border-b-slate-50 shadow-sm text-sm text-purple-700 cursor-pointer"
                >
                  <a
                    class="-mt-1"
                    (click)="
                      markNotificationsAsRead(notificationsData.notifications)
                    "
                    >Mark all as read</a
                  >
                </div>
                <div
                  class="flex m-20 text-center justify-center items-center"
                  *ngIf="userNotificationsService.isLoading"
                >
                  <mat-spinner [diameter]="30"></mat-spinner>
                </div>
                <ul
                  *ngIf="
                    !userNotificationsService.isLoading &&
                    notificationsData.notifications &&
                    notificationsData.notifications.length
                  "
                >
                  <li
                    (click)="notificationItemClicked(item)"
                    *ngFor="let item of notificationsData.notifications"
                    class="cursor-pointer hover:bg-slate-200 p-5"
                    [ngClass]="{ 'bg-slate-50': !item.read }"
                  >
                    <div class="flex items-center justify-center">
                      <mat-icon
                        *ngIf="item.valid"
                        class="text-green-700 text-[30px]"
                        >check_circle</mat-icon
                      >
                      <mat-icon
                        *ngIf="!item.valid"
                        class="text-red-700 text-[30px]"
                        >error</mat-icon
                      >
                      <div class="flex flex-col ml-3 flex-1">
                        <div class="flex items-center justify-between mb-1">
                          <span class="font-bold text-base">
                            {{ item.content }}
                            <span
                              *ngIf="
                                item.carrierSupplier || item.carrierCustomer
                              "
                              >for
                            </span>
                            <span *ngIf="item.carrierSupplier"
                              >supplier
                              {{ item.carrierSupplier.aliasName }}</span
                            >
                            <span *ngIf="item.carrierCustomer"
                              >customer
                              {{ item.carrierCustomer.aliasName }}</span
                            >
                          </span>
                        </div>
                        <span
                          *ngIf="item.operation === 'compare' && item.valid"
                          class="text-sm leading-3"
                        >
                          Check and publish your price list compare results
                        </span>
                        <span
                          *ngIf="item.operation === 'publish' && item.valid"
                          class="text-sm leading-3"
                        >
                          Check the published price list
                        </span>
                        <span
                          *ngIf="item.operation === 'compare' && !item.valid"
                          class="text-sm leading-3"
                        >
                          There are some errors in your price list
                        </span>
                        <div class="flex justify-between">
                          <span class="text-xs text-gray-500 mt-2">{{
                            item.createTime + "Z" | amTimeAgo
                          }}</span>
                          <span
                            *ngIf="!item.read"
                            class="w-2 h-2 rounded-full animate-pulse bg-purple-700 ml-1"
                          >
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div
                  *ngIf="
                    !userNotificationsService.isLoading &&
                    !notificationsData.notifications?.length
                  "
                  class="flext justify-center items-center text-sm text-center my-6"
                >
                  No notifications at the moment
                </div>
              </div>
            </ng-template>
          </p-overlayPanel>
        </ng-container> -->
        <a class="cursor-pointer" title="Logout" (click)="logout()">
          <mat-icon>logout</mat-icon>
        </a>
      </ng-container>
    </div>
  </mat-toolbar>

  <mat-drawer-container class="h-screen" [hidden]="!layout.showSideBar">
    <mat-drawer #drawer class="p-2 left-0 bg-white" mode="over">
      <mat-selection-list [multiple]="false">
        <ng-container *ngFor="let route of breadcrumbRoutes">
          <mat-list-option
            *ngxPermissionsOnly="route.permissions"
            (click)="drawer.close()"
            routerLink="{{ route.url }}"
            [routerLinkActive]="['text-main']"
          >
            <button class="text-sm inline-flex">
              <mat-icon>{{ route.icon }}</mat-icon>
              <div class="ml-4 text-sm">
                {{ route.name }}
              </div>
            </button>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </mat-drawer>
    <div
      id="top"
      class="overflow-auto"
      [ngClass]="layout.showHeader ? 'normalPage' : 'loginPage'"
      (scroll)="onScroll()"
    >
      <router-outlet>
        <div
          *ngIf="windowScrolled"
          class="bottom-0 text-4xl cursor-pointer fixed right-0 z-50 mt-0 mb-12 mr-8 ml-8"
        >
          <button (click)="scrollToTop()">
            <mat-icon [inline]="true">vertical_align_top</mat-icon>
          </button>
        </div>
      </router-outlet>
    </div>
  </mat-drawer-container>
</ng-container>
