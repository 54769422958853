import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

import { Layout } from '../models';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  layout$: Observable<Layout>;

  private layoutSubject = new BehaviorSubject({
    showHeader: false,
    showSideBar: false,
  });

  constructor() {
    this.layout$ = this.layoutSubject.asObservable();
  }

  setPublicLayout(): Observable<Layout> {
    const layout: Layout = {
      showHeader: false,
      showSideBar: false,
    };

    this.layoutSubject.next(layout);

    return this.layout$;
  }

  setPrivateLayout(): Observable<Layout> {
    const layout: Layout = {
      showHeader: true,
      showSideBar: true,
    };

    this.layoutSubject.next(layout);

    return this.layout$;
  }

  setCustomLayout(layout: Layout) {
    if (layout) {
      this.layoutSubject.next(layout);
    }
  }
}
