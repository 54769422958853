import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { Country } from '../models';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountriesService extends RestDataSource<Country> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.countriesApi, errorHandlerService, false);
  }

  getAllByRegionId(regionId: string): Observable<Country[]> {
    return this.httpClient.get(`${this.baseUrl}/all?regionId=${regionId}`).pipe(
      catchError((err: any) => this.errorHandlerService.handleError(err)),
      map((data: any) => data?.result),
      map((data: any) =>
        this.filterData && data ? data.filter((d: any) => d.active) : data
      ), // Filter data based on its active flag
      map((data: any) => {
        return data.map((item: any) => ({ ...item, inactive: !item.active }));
      }),
      tap((data) => console.log(data))
    );
  }

  getAllByZoneId(zoneId: string): Observable<Country[]> {
    return this.httpClient.get(`${this.baseUrl}/all?zoneId=${zoneId}`).pipe(
      catchError((err: any) => this.errorHandlerService.handleError(err)),
      map((data: any) => data?.result),
      map((data: any) =>
        this.filterData && data ? data.filter((d: any) => d.active) : data
      ), // Filter data based on its active flag
      map((data: any) => {
        return data.map((item: any) => ({ ...item, inactive: !item.active }));
      }),
      tap((data) => console.log(data))
    );
  }

  addUpdate(country: Country): Observable<Country | null> {
    if (country?.id) {
      const countryToBeEdited = { ...country };

      countryToBeEdited.name = country.name.trim();
      countryToBeEdited.tomiaCountryNaming = country.tomiaCountryNaming.trim();
      countryToBeEdited.rmiCountryNaming = country.rmiCountryNaming.trim();
      countryToBeEdited.regionId = country.regionId;
      countryToBeEdited.zoneId = country.zoneId;
      countryToBeEdited.version = country.version;

      return this.update(country.id, countryToBeEdited);
    } else {
      const countryToBeAdded = {
        name: country.name.trim(),
        version: country.version,
        tomiaCountryNaming: country.tomiaCountryNaming.trim(),
        rmiCountryNaming: country.rmiCountryNaming.trim(),
        regionId: country.regionId,
        zoneId: country.zoneId,
      };

      return this.add(countryToBeAdded);
    }
  }
}
