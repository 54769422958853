<div
  class="flex w-full text-sm border-b-1 border-slate-200 items-center p-[5px]"
  [ngClass]="{
    'justify-end': !paginationTitle,
    'justify-between': paginationTitle
  }"
  *ngIf="showActionsStatusBar"
>
  <span class="ml-5" *ngIf="dataSource && paginationTitle">
    Displaying {{ dataSource.length }} of {{ dataSource.length }}
    {{ paginationTitle }}
  </span>
  <div class="flex space-x-4 h-10">
    <div *ngxPermissionsOnly="domoPermissions" class="flex">
      <button
        type="button"
        pButton
        icon="pi pi-send"
        pRipple
        class="mr-2"
        (click)="sendToDomoClicked.emit()"
        pTooltip="Push to DOMO"
        tooltipPosition="bottom"
        *ngIf="showSendToDomo"
      >
      </button>
    </div>
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-file"
      (click)="this.exportToCSV()"
      class="mr-2"
      pTooltip="CSV"
      tooltipPosition="bottom"
      *ngIf="showCSV"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-file-excel"
      (click)="this.exportToExcel()"
      class="p-button-success mr-2"
      pTooltip="XLS"
      tooltipPosition="bottom"
      *ngIf="showExcel"
    ></button>

    <!-- <button
      pButton
      label="Clear"
      class="p-button-outlined"
      icon="pi pi-filter-slash"
      (click)="clear()"
    ></button> -->
    <button
      *ngIf="showRefreshButton"
      (click)="refreshClicked.emit()"
      title="Refresh"
    >
      <mat-icon>refresh</mat-icon>
    </button>

    <ng-container *ngxPermissionsOnly="addPermissions">
      <button *ngIf="showAddButton" (click)="addClicked.emit()" title="Add">
        <mat-icon>add</mat-icon>
      </button>
    </ng-container>
    <div [ngClass]="{ 'h-7': !showAddButton && !showRefreshButton }"></div>
  </div>
</div>

<app-grid
  #grid
  [columnDefs]="tableColumns"
  [rowData]="dataSource"
  [totalRecords]="totalRecords"
  [lazyLoad]="lazyLoad"
  [loading]="loading"
  [showEmptyMessage]="showEmptyMessage"
  [paginator]="paginator"
  [scrollable]="scrollable"
  [scrollHeight]="scrollHeight"
  (rowSelected)="rowClicked.emit($event)"
  (lazyLoadChanged)="lazyLoadChanged.emit($event)"
  [showMenu]="showMenu"
  [menuItems]="menuItems"
  (menuItemClicked)="menuItemClicked.emit($event)"
  [showStatusColumn]="showStatusColumn"
  [enableSelection]="enableSelection"
  [virtualScroll]="virtualScroll"
  [virtualScrollItemSize]="virtualScrollItemSize"
  [pageSize]="pageSize"
  [showCSV]="showCSV"
>
</app-grid>
