import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { localStorageKeys } from './../../config/local-storage-keys';
import { ErrorHandlerService } from './error-handler.service';
import { UserService } from './user.service';
import { KeycloakService } from 'keycloak-angular';
import { UserInfo } from '../models';
import { testingAuthorities } from '@bilateral-roaming/config';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authToken!: any;
  username$!: Observable<string>;

  private usernameSubject = new BehaviorSubject('');

  constructor(
    private httpClient: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private userService: UserService,
    private keycloakService: KeycloakService
  ) {
    const authTokenLocalStorage = localStorage.getItem(
      localStorageKeys.authToken
    );
    const usernameLocalStorage = localStorage.getItem(
      localStorageKeys.username
    );

    if (authTokenLocalStorage) {
      this.authToken = JSON.parse(authTokenLocalStorage);
    }

    if (usernameLocalStorage) {
      this.setUsername(usernameLocalStorage);
    }

    this.username$ = this.usernameSubject.asObservable();

    this.userService.userLoggedOut$.subscribe((loggedOut: boolean) =>
      loggedOut ? this.removeAuthToken() : ''
    );

    window.addEventListener(
      'storage',
      (event) => {
        if (event.storageArea == localStorage) {
          let token = localStorage.getItem(localStorageKeys.authToken);

          if (token == undefined) {
            this.removeAuthToken();
            // this.router.navigate(['auth', 'login']);
            window.location.reload();
          }
        }
      },
      false
    );
  }

  getUsername(): Observable<UserInfo> {
    if (environment.UseKeycloak) {
      // Rakuten
      return this.httpClient.get<UserInfo>(
        'https://stg-rcpauth.rmb-lab.jp/auth/realms/Rakuten-OSS-Stg/protocol/openid-connect/userinfo'
      );
    } else {
      // Local
      return of({
        sub: 'test',
        email_verified: true,
        name: 'testing',
        preferred_username: 'Testing',
        given_name: 'Test',
        locale: 'computer',
        family_name: 'Prueba',
        email: 'test@test.com',
      } as UserInfo);
    }
  }

  setUsername(username: string) {
    this.usernameSubject.next(username);
    localStorage.setItem(localStorageKeys.username, username);
  }

  getCurrentUserAuthorities(): Observable<string[]> {
    if (environment.UseKeycloak && environment.UseUM) {
      // Rakuten
      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('audience', 'apim');
      return this.httpClient
        .get(
          'https://stg-sapigw-ext.rmb-lab.jp/apim/um/2.1.23/rest/User/getPermissions',
          { headers }
        )
        .pipe(
          catchError((err: any) => this.errorHandlerService.handleError(err)),
          map((data: any) => data?.map((a: any) => a))
        );
    } else {
      // Local
      return of(testingAuthorities);
    }
  }

  private removeAuthToken() {
    this.authToken = null;
  }
}
