import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-data-not-found',
  templateUrl: './data-not-found.component.html',
  styleUrls: ['./data-not-found.component.scss'],
})
export class DataNotFoundComponent implements OnInit {
  @Input() message = 'Data not found at this moment.';
  @Input() extraClasses = '';
  @Input() addText = '';
  @Input() showAdd = true;
  @Input() permissions: string[] = [];
  @Output() addAction = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
