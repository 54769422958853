import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generalConstants, BackendApi } from '@bilateral-roaming/config';
import { sortBy } from 'lodash';

import {
  BehaviorSubject,
  combineLatest,
  interval,
  Observable,
  Subscription,
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationsService {
  notifications$!: Observable<any[]>;
  unreadNotificationsCount$!: Observable<number>;
  notificationsData$: Observable<{ notifications: any[]; unreadCount: number }>;
  isLoading = false;

  private notificationsSubject: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);

  private latestNotifications!: any[];
  private latestUnreadCount!: number;
  private unreadNotificationsCountSubject = new BehaviorSubject(0);
  private notificationsTimerSubscription!: Subscription;

  constructor(
    protected httpClient: HttpClient,
    protected errorHandlerService: ErrorHandlerService
  ) {
    this.notifications$ = this.notificationsSubject.asObservable();
    this.unreadNotificationsCount$ =
      this.unreadNotificationsCountSubject.asObservable();

    this.notificationsData$ = combineLatest([
      this.notifications$,
      this.unreadNotificationsCount$,
    ]).pipe(
      map(([notifications, count]) => ({ notifications, unreadCount: count }))
    );
  }

  getAllUserNotifications(isPolling: boolean) {
    // this.isLoading = !isPolling;
    // this.httpClient
    //   .get(`${BackendApi.allNotifications}`)
    //   .pipe(
    //     catchError((err: any) => this.errorHandlerService.handleError(err)),
    //     map((data: any) => data.result),
    //     map((notificationsList: any[]) =>
    //       sortBy(notificationsList, 'createTime').reverse()
    //     ),
    //     tap((reversedNotificationsList: any[]) => {
    //       this.updateNotifications(reversedNotificationsList);
    //     }),
    //     tap(() => (this.isLoading = false)),
    //     tap((reversedNotificationsList) =>
    //       console.log(reversedNotificationsList)
    //     )
    //   )
    //   .subscribe();
  }

  pollForNotifications() {
    this.notificationsTimerSubscription = interval(10000)
      .pipe(tap(() => this.getAllUserNotifications(true)))
      .subscribe();
  }

  stopNotificationsPolling() {
    if (this.notificationsTimerSubscription) {
      this.notificationsTimerSubscription.unsubscribe();
    }
  }

  getAllUserUnreadNotifications() {
    // this.isLoading = true;
    // this.httpClient
    //   .get(`${BackendApi.unreadNotifications}`)
    //   .pipe(
    //     catchError((err: any) => this.errorHandlerService.handleError(err)),
    //     map((data: any) => data.result),
    //     map((notificationsList: any[]) =>
    //       sortBy(notificationsList, 'createTime').reverse()
    //     ),
    //     tap((reversedNotificationsList: any[]) => {
    //       this.updateNotifications(reversedNotificationsList);
    //     }),
    //     tap(() => (this.isLoading = false)),
    //     tap((reversedNotificationsList) =>
    //       console.log(reversedNotificationsList)
    //     )
    //   )
    //   .subscribe();
  }

  markNotificationsAsRead(notifications: any[]) {
    // if (notifications.length > 1) {
    //   this.isLoading = true;
    // } else {
    //   this.isLoading = false;
    // }
    // const payload = {
    //   notificationIds: notifications.map((n) => n.id),
    // };
    // this.httpClient
    //   .put(`${BackendApi.acknowledgeNotifications}/update`, payload)
    //   .pipe(
    //     catchError((err: any) => this.errorHandlerService.handleError(err)),
    //     map((data: any) => data.result),
    //     tap(() => (this.isLoading = false)),
    //     tap((data) => console.log(data)),
    //     tap((notifications: any[]) => {
    //       this.updateReadNotifications(notifications);
    //     }),
    //     tap((notifications) => console.log(notifications))
    //   )
    //   .subscribe();
  }

  private updateNotifications(notifications: any[]) {
    this.notificationsSubject.next(notifications);
    this.latestNotifications = notifications;

    const unreadNotificationsCount =
      notifications.filter((n) => !n.read)?.length || 0;

    this.unreadNotificationsCountSubject.next(unreadNotificationsCount);
    this.latestUnreadCount = unreadNotificationsCount;
  }

  private updateReadNotifications(notifications: any[]) {
    notifications.forEach((notification) => {
      let index = this.latestNotifications.findIndex(
        (i) => i.id === notification.id
      );

      if (index != -1) {
        this.latestNotifications.splice(index, 1, notification);
      }
    });

    this.notificationsSubject.next(this.latestNotifications);

    const unreadNotificationsCount =
      this.latestNotifications.filter((n) => !n.read)?.length || 0;

    this.unreadNotificationsCountSubject.next(unreadNotificationsCount);
    this.latestUnreadCount = unreadNotificationsCount;
  }
}
