import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Column, GridMenuItem } from '@bilateral-roaming/core/models';
import { Authorities } from '@bilateral-roaming/config';

@Component({
  selector: 'app-table-grid',
  templateUrl: './table-grid.component.html',
  styleUrls: ['./table-grid.component.scss'],
})
export class TableGridComponent {
  @ViewChild('grid') grid: any;

  @Input() tableColumns: Column[] = [];
  @Input() dataSource = [];
  @Input() showActionsColumn: boolean = false;
  @Input() paginationTitle!: string;
  @Input() showActionsStatusBar = true;
  @Input() showRefreshButton = true;
  @Input() showAddButton = false;
  @Input() showMenu = false;
  @Input() lazyLoad = false;
  @Input() loading = false;
  @Input() showEmptyMessage: boolean = false;
  @Input() menuItems!: GridMenuItem[];
  @Input() addPermissions!: string[];
  @Input() domoPermissions!: string[];
  @Input() totalRecords!: number;
  @Input() scrollable: boolean = false;
  @Input() scrollHeight!: string;
  @Input() paginator: boolean = false;
  @Input() showStatusColumn: boolean = true;
  @Input() enableSelection: boolean = true;
  @Input() virtualScroll: boolean = false;
  @Input() virtualScrollItemSize!: number;
  @Input() pageSize: number = 10;
  @Input() showCSV: boolean = false;
  @Input() showExcel: boolean = false;
  @Input() showPDF: boolean = false;
  @Input() showSendToDomo: boolean = false;

  @Output() sendToDomoClicked = new EventEmitter();
  @Output() refreshClicked = new EventEmitter();
  @Output() addClicked = new EventEmitter();
  @Output() rowClicked = new EventEmitter<{ data: any }>();
  @Output() lazyLoadChanged = new EventEmitter();
  @Output() rowButtonClicked = new EventEmitter();
  @Output() menuItemClicked = new EventEmitter<{
    menuItem: GridMenuItem;
    data: any;
  }>();

  authorities = Authorities;
  JSON = JSON;
  displayedColumns: Array<string> = [];

  constructor() {}

  ngOnInit(): void {
    if (this.showActionsColumn) {
      this.displayedColumns = [
        ...this.tableColumns.map((c) => c.columnDef),
        'actions',
      ];
    } else if (this.showMenu) {
      this.displayedColumns = [
        ...this.tableColumns.map((c) => c.columnDef),
        'menu',
      ];
    } else {
      this.displayedColumns = this.tableColumns.map((c) => c.columnDef);
    }
  }
  clear() {
    this.grid.clearTable();
  }

  exportToCSV() {
    this.grid.exportToCSV();
  }

  exportToExcel() {
    this.grid.exportToExcel();
  }
}
