import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';
import { catchError, map, tap } from 'rxjs/operators';
import { CountryOperator } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CountriesOperatorsService extends RestDataSource<CountryOperator> {
    constructor(
      override httpClient: HttpClient,
      override errorHandlerService: ErrorHandlerService
    ) {
      super(httpClient, BackendApi.countriesOperatorsApi, errorHandlerService, false);
    }

  getAllOperatorsByCountryId(countryId: string): Observable<CountryOperator[]> {
    return this.httpClient.get(`${this.baseUrl}/all?countryId=${countryId}`).pipe(
      catchError((err: any) => this.errorHandlerService.handleError(err)),
      map((data: any) => data?.result),
      map((data: any) =>
        this.filterData && data ? data.filter((d: any) => d.active) : data
      ), // Filter data based on its active flag
      map((data: any) => {
        return data.map((item: any) => ({ ...item, inactive: !item.active }));
      }),
      tap((data) => console.log(data))
    );
  }

  addUpdate(
    countriesOperators: CountryOperator
  ): Observable<CountryOperator | null> {
    if (countriesOperators?.id) {
      const dataToBeEdited = { ...countriesOperators };
      dataToBeEdited.version = countriesOperators.version;

      return this.update(countriesOperators.id, dataToBeEdited);
    } else {
      const dataToBeAdded = {
        ...countriesOperators,
      };

      return this.add(dataToBeAdded);
    }
  }
}
