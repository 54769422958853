import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Region } from '../models/regions';
import { ErrorHandlerService } from './error-handler.service';
import { RestDataSource } from './rest-data-source';
import { BackendApi } from '@bilateral-roaming/config';

@Injectable({
  providedIn: 'root',
})
export class RegionsService extends RestDataSource<Region> {
  constructor(
    override httpClient: HttpClient,
    override errorHandlerService: ErrorHandlerService
  ) {
    super(httpClient, BackendApi.regionsApi, errorHandlerService, false);
  }

  addUpdate(region: Region): Observable<Region | null> {
    if (region?.id) {
      const regionToBeEdited = { ...region };

      regionToBeEdited.name = region.name.trim();
      regionToBeEdited.version = region.version;

      return this.update(region.id, regionToBeEdited);
    } else {
      const regionToBeAdded = {
        name: region.name.trim(),
        version: region.version,
      };
      return this.add(regionToBeAdded);
    }
  }
}
