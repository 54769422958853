<div>
  <h1 mat-dialog-title>
    {{ title }}
  </h1>

  <div mat-dialog-content>
    <p>{{ message }}</p>
  </div>

  <div class="flex items-center">
    <button class="!ml-auto !mr-4" mat-button (click)="onDismiss()">No</button>

    <button mat-raised-button class="bg-black text-white" (click)="onConfirm()">
      Yes
    </button>
  </div>
</div>
